// import  axios  from "axios";
// import configs from "./configs";



// export function postAPICall(url,param, callback, errorcallback,headers){

//     axios.post(url,param,{ headers })
//     .then(res => {
//       if(callback != null){
//          callback(res);
//       }
//     })
//     .catch(err => {
//       if(errorcallback != null){
      
//          errorcallback(err);
//          // console.log(err);
//       }
//     })
// }


// export function getAPICall(url,config, callback, errorcallback){
   
//     axios.get(url, config)
//     .then(res => {
//       //do something
//       if(callback != null){
//          callback(res);
//       }
//     })
//     .catch(err => {
//       // catch error
//       if(errorcallback != null){
//          errorcallback(err);
//       }
//     })
//   }



// import configs from "./configs";

export function postAPICall(url, param, callback, errorcallback, headers) {
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers, 
    },
    body: JSON.stringify(param), 
  })
  .then(res => res.json()) 
  .then(data => {
    if (callback != null) {
      callback(data);
    }
  })
  .catch(err => {
    if (errorcallback != null) {
      errorcallback(err);
    }
    // console.log(err);
  });
}

export function getAPICall(url, config, callback, errorcallback) {
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...config?.headers, 
    },
  })
  .then(res => res.json())
  .then(data => {
    if (callback != null) {
      callback(data);
    }
  })
  .catch(err => {
    if (errorcallback != null) {
      errorcallback(err);
    }
    // console.log(err);
  });
}
