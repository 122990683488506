import React from 'react';
import "./assets/styles/_index.scss";

const LoadingModal = ({ isLoading, progress }) => {
  if (!isLoading) return null;

  return (
<div className="loading-overlay">
  <div className="spinner-wrapper">
    <div className="spinner"></div>
    <div className="spinner-logo"></div> 
  </div>
  {/* <p className="loading-text">Content Downloading...!!!</p>  */}
</div>

  );
};

export default LoadingModal;
